export function useFonts(jobShop: JobShop | null) {
  const { public: { tcCdnURL } } = useRuntimeConfig()

  // sets selected fonts & font-weights
  const selectedFontHeading = jobShop?.["fonts-and-colors"].config.general.fonts.heading
  const selectedFontBody = jobShop?.["fonts-and-colors"].config.general.fonts.body
  const selectedFontButton = jobShop?.["fonts-and-colors"].config.general.fonts.button
  const selectedFontWeightHeading = jobShop?.["fonts-and-colors"].config.general.fontWeights.heading
  const selectedFontWeightBody = jobShop?.["fonts-and-colors"].config.general.fontWeights.body
  const selectedFontWeightButton = jobShop?.["fonts-and-colors"].config.general.fontWeights.button

  if (import.meta.client) {
    document.documentElement.style.setProperty("--cr-heading-font", `${selectedFontHeading?.value}`)
    document.documentElement.style.setProperty("--cr-body-font", `${selectedFontBody?.value}`)
    document.documentElement.style.setProperty("--cr-button-font", `${selectedFontButton?.value}`)
    document.documentElement.style.setProperty("--cr-heading-font-weight", `${selectedFontWeightHeading?.value}`)
    document.documentElement.style.setProperty("--cr-body-font-weight", `${selectedFontWeightBody?.value}`)
    document.documentElement.style.setProperty("--cr-button-font-weight", `${selectedFontWeightButton?.value}`)
  }

  // Load font-face definition CSS from CDN for selected custom font(s)
  const fontLinks = [{ rel: "stylesheet", href: `${tcCdnURL}/fonts/${selectedFontHeading?.value}/style.css` }]
  if (selectedFontHeading?.value !== selectedFontBody?.value)
    fontLinks.push({ rel: "stylesheet", href: `${tcCdnURL}/fonts/${selectedFontBody?.value}/style.css` })

  if (selectedFontBody?.value !== selectedFontButton?.value)
    fontLinks.push({ rel: "stylesheet", href: `${tcCdnURL}/fonts/${selectedFontButton?.value}/style.css` })

  useHead({
    link: fontLinks,
  })
}
